import { Logo } from '../../Testimonials/types';

export const logos: Logo[] = [
	{
		src: '/_n/images/homepage/logo-nyt.webp',
		alt: 'New York Times',
		width: 218,
		height: 36,
	},
	{
		src: '/_n/images/homepage/logo-wsj.webp',
		alt: 'Wall Street Journal',
		width: 64,
		height: 36,
	},
	{
		src: '/_n/images/homepage/logo-fortune.webp',
		alt: 'Fortune',
		width: 140,
		height: 36,
	},
	{
		src: '/_n/images/homepage/logo-today.webp',
		alt: 'Today',
		width: 180,
		height: 36,
	},
	{
		src: '/_n/images/homepage/logo-cnn.webp',
		alt: 'CNN',
		width: 64,
		height: 36,
	},
	{
		src: '/_n/images/homepage/logo-usatoday.webp',
		alt: 'USA Today',
		width: 184,
		height: 36,
	},
];
