import styled, { keyframes } from 'styled-components';
import { theme } from 'styled-tools';

const rotateAnimation = keyframes`
    0% {
        transform: rotate(0deg) scale(1)
    }
    25% {
        transform: rotate(90deg) scale(1.25)
    }
    50% {
        transform: rotate(180deg) scale(1.5)
    }
    75% {
        transform: rotate(270deg) scale(1.25)
    }
    100% {
        transform: rotate(360deg) scale(1)
    }
`;

export const Wrapper = styled.div<{
	$isLoading: boolean;
}>`
	display: flex;
	justify-content: center;
	margin: 0 auto;
	opacity: ${p => (p.$isLoading ? 1 : 0)};
	padding: 40px 0;
	position: relative;
	transition: ${theme('animation.transition')};
`;

export const LoaderOuter = styled.div`
	background: linear-gradient(180deg, rgba(226, 234, 255, 0) 20%, #e2eaff 80%);
	border-radius: 100%;
	height: 150px;
	position: relative;
	width: 150px;
`;

export const LoaderInner = styled.div`
	&:before {
		animation: 2s ${rotateAnimation} linear infinite;
		border-bottom: 0 solid #ffffff05;
		border-radius: 100%;
		box-shadow: 0 -10px 20px 20px #ffffff40 inset, 0 -5px 15px 10px #ffffff50 inset,
			0 -2px 5px #ffffff80 inset, 0 -3px 2px #ffffffbb inset, 0 2px 0px rgba(226, 234, 255), 0 2px 3px #ffffff,
			0 5px 5px #ffffff90, 0 10px 15px #ffffff60, 0 10px 20px 20px #ffffff40;
		content: '';
		filter: blur(3px);
		height: 100%;
		position: absolute;
		width: 100%;
	}
`;
