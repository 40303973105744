import { MDXProvider } from '@mdx-js/react';
import { useIntl } from 'react-intl';

import { useLocale } from '@/hooks/locale/useLocale';

import messages from './messages';
import { Wrapper, FaqList, Heading } from './styles';

const FAQ = ({ FaqSections }: { FaqSections: React.FC }) => {
	const { formatMessage } = useIntl();
	const { calmLanguage } = useLocale();

	if (calmLanguage !== 'en') return null;

	return (
		<MDXProvider>
			<Wrapper>
				<Heading id="faq-heading">{formatMessage(messages.heading)}</Heading>
				<FaqList aria-labelledby="faq-heading">
					<FaqSections />
				</FaqList>
			</Wrapper>
		</MDXProvider>
	);
};

export default FAQ;
