import { defineMessages } from 'react-intl';

import { Review } from '../../Testimonials/types';

export const messages = defineMessages({
	reviewsHeading: {
		id: 'homepage.testimonials.reviewsHeading2',
		defaultMessage: 'Over 2 million 5-star reviews.',
		description: 'Heading for section showing users that love Calm',
	},
	review1Quote: {
		id: 'homepage.testimonials.review1quote',
		defaultMessage: 'When I cannot fall asleep, I turn on this app and am out within 5 minutes.',
		description: 'Quote from a Calm app review',
	},
	review1Author: {
		id: 'homepage.testimonials.review1Author',
		defaultMessage: 'Brandy from Houston',
		description: 'Author of Calm app review',
	},
	review2Quote: {
		id: 'homepage.testimonials.review2quote',
		defaultMessage:
			'I have a very busy brain and can find it hard to unwind. Now a daily practice is actually so wonderful and healing for me.',
		description: 'Quote from a Calm app review',
	},
	review2Author: {
		id: 'homepage.testimonials.review2Author',
		defaultMessage: 'John from Chicago',
		description: 'Author of Calm app review',
	},
	review3Quote: {
		id: 'homepage.testimonials.review3quote',
		defaultMessage:
			'Calm has changed my life in immeasurable ways. I am more resilient and feel so much more connected to myself.',
		description: 'Quote from a Calm app review',
	},
	review3Author: {
		id: 'homepage.testimonials.review3Author',
		defaultMessage: 'Allison from San Jose',
		description: 'Author of Calm app review',
	},
	review4Quote: {
		id: 'homepage.testimonials.review4quote',
		defaultMessage:
			"Whenever I need to unwind from a stressful work day I meditate with Calm's soundscapes and it automatically sends me to my happy place.",
		description: 'Quote from a Calm app review',
	},
	review4Author: {
		id: 'homepage.testimonials.review4Author',
		defaultMessage: 'Jasmine from Bend',
		description: 'Author of Calm app review',
	},
	review5Quote: {
		id: 'homepage.testimonials.review5quote',
		defaultMessage:
			'My family loves Calm! Out of the three meditation apps I have on my phone, Calm is the one I actually use.',
		description: 'Quote from a Calm app review',
	},
	review5Author: {
		id: 'homepage.testimonials.review5Author',
		defaultMessage: 'Kristie from Irvine',
		description: 'Author of Calm app review',
	},
	review6Quote: {
		id: 'homepage.testimonials.review6quote',
		defaultMessage:
			'Calm cuts through my stress, anxiety, irregular sleep schedule and brings me to deep sleep. I usually fall asleep within 5 minutes.',
		description: 'Quote from a Calm app review',
	},
	review6Author: {
		id: 'homepage.testimonials.review6Author',
		defaultMessage: 'Mathieu from New Orleans',
		description: 'Author of Calm app review',
	},
	review7Quote: {
		id: 'homepage.testimonials.review7quote',
		defaultMessage:
			'I have tried other meditation and gratitude apps, but this one has everything and the best teachers.',
		description: 'Quote from a Calm app review',
	},
	review7Author: {
		id: 'homepage.testimonials.review7Author',
		defaultMessage: 'Jen from Scarsdale',
		description: 'Author of Calm app review',
	},
	review8Quote: {
		id: 'homepage.testimonials.review8quote',
		defaultMessage:
			'My wife and I have listened to Calm for well over 2 years. Cannot express how easy it is to fall asleep listening to this app.',
		description: 'Quote from a Calm app review',
	},
	review8Author: {
		id: 'homepage.testimonials.review8Author',
		defaultMessage: 'Aaron from Louisville',
		description: 'Author of Calm app review',
	},
	review9Quote: {
		id: 'homepage.testimonials.review9quote',
		defaultMessage:
			"Medication isn't for me, so I decided to give Calm a try. Now I begin and end every day with guided meditations.",
		description: 'Quote from a Calm app review',
	},
	review9Author: {
		id: 'homepage.testimonials.review9Author',
		defaultMessage: 'Tracy from Newark',
		description: 'Author of Calm app review',
	},
});

export const reviews: Review[] = [
	{
		quote: messages.review1Quote,
		author: messages.review1Author,
	},
	{
		quote: messages.review2Quote,
		author: messages.review2Author,
	},
	{
		quote: messages.review3Quote,
		author: messages.review3Author,
	},
	{
		quote: messages.review4Quote,
		author: messages.review4Author,
	},
	{
		quote: messages.review5Quote,
		author: messages.review5Author,
	},
	{
		quote: messages.review6Quote,
		author: messages.review6Author,
	},
	{
		quote: messages.review7Quote,
		author: messages.review7Author,
	},
	{
		quote: messages.review8Quote,
		author: messages.review8Author,
	},
	{
		quote: messages.review9Quote,
		author: messages.review9Author,
	},
];
