import { defineMessages } from 'react-intl';

export default defineMessages({
	heading: {
		id: 'homepage.hero.heading',
		defaultMessage: 'Calm your mind. Change your life.',
		description: 'Heading for the calm.com homepage',
	},
	body: {
		id: 'homepage.hero.body',
		defaultMessage:
			"Mental health is hard. Getting support doesn't need to be. Our app puts the tools to feel better in your back pocket, with personalized content to manage stress and anxiety, get better sleep, and feel more present in your life. Relax your mind, and wake up as the person you want to be.",
		description: 'Explanation of why the Calm app is helpful',
	},
	bodyShort: {
		id: 'homepage.hero.bodyShort2',
		defaultMessage: 'The #1 app for sleep, meditation and relaxation',
		description: 'Explanation of why the Calm app is helpful',
	},
	cta: {
		id: 'homepage.hero.cta',
		defaultMessage: 'Try Calm for Free',
		description: 'Button that takes user to free trial signup flow',
	},
	ctaActiveSub: {
		id: 'homepage.hero.ctaActiveSub',
		defaultMessage: 'Continue to App',
		description: 'Button text for when a user is logged in and has an active subscription',
	},
	ctaLoggedInIneligible: {
		id: 'homepage.hero.ctaLoggedInIneligible',
		defaultMessage: 'Subscribe to Calm',
		description: 'Button text for when a user is logged in but not eligible for the free trial',
	},
	secondaryCta: {
		id: 'homepage.hero.secondaryCta',
		defaultMessage: 'Already have an account?',
		description: 'Button that takes user to login flow',
	},
});
