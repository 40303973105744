import { CalmTheme } from '@calm-web/design-system';

export const homepageTheme = {
	name: 'homepage-refresh',
	palette: {
		...CalmTheme.palette,
		buttonPrimaryBg: 'linear-gradient(180deg, #2477AA 0%, #6461E0 100%)',
		bannerText: '#1A3E6F',
	},
};
