import { GetServerSideProps } from 'next';
import { ThemeProvider } from 'styled-components';

import AppInstallActionSheet from '@/components/appInstallActionSheet/AppInstallActionSheet';
import HeaderRefresh from '@/components/homepageRefresh/header/Header';
import HomepageLayout from '@/components/homepageRefresh/homepage/HomepageLayout';
import { homepageTheme } from '@/components/homepageRefresh/homepage/HomepageLayout/data/theme';
import Layout from '@/components/layout/LayoutWrapper';
import { InitRequest } from '@/hooks/store';
import { useAmplitudeExperiment } from '@/hooks/utils/experiments/useAmplitudeExperiment';
import { BlogPost, getBlogPosts } from '@/utils/blogPosts';
import { getInitialServerState } from '@/utils/pageGeneration/getServerSideProps';

const Home = ({ blogPosts }: { blogPosts: BlogPost[] }) => {
	const { isEnrolled } = useAmplitudeExperiment('www-app-install-action-sheet');

	return (
		<Layout pageName="homepage" showFooter>
			{isEnrolled && <AppInstallActionSheet />}
			<ThemeProvider theme={homepageTheme}>
				<HeaderRefresh />
				<HomepageLayout blogPosts={blogPosts} />
			</ThemeProvider>
		</Layout>
	);
};

export const getServerSideProps: GetServerSideProps = async context => {
	const initialState = await getInitialServerState(context, [
		InitRequest.DEVICE,
		InitRequest.USER,
		InitRequest.PRICES,
	]);

	const isEnglish = initialState?.device?.language?.toLocaleLowerCase() === 'en';
	const blogPosts = await getBlogPosts('all', isEnglish);

	return {
		props: {
			initialState,
			blogPosts,
		},
	};
};

export default Home;
