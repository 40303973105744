import styled, { css } from 'styled-components';
import { ifProp, theme } from 'styled-tools';

import { FontSizes, FontWeights, Text, fontSize, fontWeight, minWidth } from '@calm-web/design-system';
import { CalmLogo } from '@calm-web/icons';

import {
	HEADER_HEIGHT_DESKTOP,
	HEADER_HEIGHT_MOBILE,
} from '@/components/homepageRefresh/header/Header/styles';
import HomepageLoader from '@/components/homepageRefresh/homepage/HomepageLoader';

export const Wrapper = styled.section<{ isFullHeight?: boolean }>`
	padding-bottom: 60px;

	${minWidth('desktop')} {
		padding-bottom: 140px;
	}

	${ifProp(
		'isFullHeight',
		css`
			min-height: 100vh;
			min-height: 100svh;
			display: flex;
			flex-direction: column;
			padding-bottom: 0;

			& > div:first-child {
				max-height: 60vh;
				flex: 1;
				flex-basis: 180px;
			}
		`,
	)};
`;

export const HomepageHeroLoader = styled(HomepageLoader)`
	padding: calc(${HEADER_HEIGHT_MOBILE} + 40px) 0 40px;
	${minWidth('desktop')} {
		padding-top: calc(${HEADER_HEIGHT_DESKTOP} + 40px);
	}
`;

export const JasperLake = styled.div`
	height: 180px;
	margin-bottom: 32px;
	position: relative;
	z-index: 0;

	${minWidth('desktop')} {
		height: 480px;
		margin-bottom: 40px;
	}

	&:after {
		background: linear-gradient(
			180deg,
			rgba(247, 247, 247, 0) 0%,
			rgba(247, 247, 247, 0.0885417) 8.85%,
			#f7f7f7 100%
		);
		height: 40px;
		bottom: 0;
		content: '';
		left: 0;
		position: absolute;
		right: 0;

		${minWidth('desktop')} {
			background: linear-gradient(
				180deg,
				rgba(247, 247, 247, 0) 0%,
				rgba(247, 247, 247, 0.0885417) 8.85%,
				#f7f7f7 100%
			);

			height: 100px;
		}
	}
`;
export const AppSection = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin: 0 auto;
	max-width: 1440px;
	padding: 0 20px;
	text-align: center;

	${minWidth('desktop')} {
		flex-direction: row;
		padding: 0 136px;
	}
	transition: ${theme('animation.transition')};
`;
export const StickyCopy = styled.div`
	margin-bottom: 40px;

	${minWidth('desktop')} {
		align-self: flex-start;
		margin-bottom: 0;
		position: sticky;
		top: 240px;
	}
`;
export const Heading = styled(Text).attrs({
	el: 'h1',
	color: 'bannerText',
	noMargin: true,
})`
	&&& {
		${fontSize(FontSizes['2xl'])}
		line-height: 150%;
		margin: 0 auto 8px;
		max-width: 250px;

		${minWidth('mobileLarge')} {
			max-width: none;
		}

		${minWidth('desktop')} {
			${fontSize(FontSizes['4xl'])}
		}
	}
`;
export const Body = styled(Text).attrs({
	el: 'p',
	noMargin: true,
	color: 'blackAlpha80',
})<{ isShortVersion?: boolean }>`
	&&& {
		margin-bottom: 24px;
		${ifProp(
			'isShortVersion',
			css`
				${fontSize(FontSizes.base)}
				margin-bottom: 36px;
				margin-top: 16px;

				${minWidth('desktop')} {
					${fontSize(FontSizes.lg)}
					${fontWeight(FontWeights.Medium)};
				}
			`,
		)};
	}
`;

export const ButtonContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 16px;

	${minWidth('desktop')} {
		flex-direction: row;
		gap: 12px;
	}
`;

export const PreviewContent = styled.div`
	border: 8px #dedede solid;
	border-radius: 32px;
	flex: 0 0 433px;
	height: 433px;
	overflow: hidden;
	position: relative;
	width: 200px;
	z-index: 0;

	${minWidth('desktop')} {
		flex: 0 0 300px;
		height: 650px;
		width: 300px;
	}
`;
export const Logo = styled(CalmLogo)`
	position: absolute;
	left: 50%;
	transform: translate3d(-50%, 50px, 0);
	z-index: 1;
`;
export const PreviewVideo = styled.video`
	bottom: 0;
	height: 100%;
	left: 0;
	object-fit: cover;
	object-position: center;
	position: absolute;
	right: 0;
	top: 0;
	width: 200px;

	${minWidth('desktop')} {
		width: 300px;
	}
`;
